.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}

.nav-item,
.navbar {
  z-index: 10000;
}

.widget {
  background: rgba(154, 195, 233, 0.61);
  padding: 0px 4px; 
  display: flex;
  border: 1px dashed #ffffff;
  width: fit-content;
  
}

.widget:hover{
  border: 1px dashed #ffffff;
}

.carousel-inner{
  height: 100%;
}

@media screen and (max-width:800px) {
  .sidebar {
    z-index: 10000000;
  }
}




.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

#preview {
  /* background: transparent url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png') no-repeat; */
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}

[data-inviewport="scale-in"] { 
  transition: 1s;
  transform: scale(0);
}
[data-inviewport="scale-in"].is-inViewport { 
  transform: scale(1);
}

[data-inviewport="fade-rotate"] { 
  transition: 2s;
  opacity: 0;
}
[data-inviewport="fade-rotate"].is-inViewport { 
  transform: rotate(180deg);
  opacity: 1;
}

[data-inviewport="collapse"]{
  transform: scaleY(0);
  transition: transform 0.6s ease-out;
}

[data-inviewport="collapse"].is-inViewport {
  transform: scaleY(1)
}

[data-inviewport="fade"] { 
  transition: 2s;
  opacity: 0;
}
[data-inviewport="fade"].is-inViewport { 
  opacity: 1;
}

[data-inviewport='slide-bottom']{
  transform: translateY(100%);
  opacity: 0;
  transition: 1s;
}

[data-inviewport='slide-bottom'].is-inViewport{
  transform: translateY(0%);
  opacity: 1;
}

[data-inviewport='slide-top']{
  transition: 1s;
  transform: translateY(-100%);
  opacity: 0;
}

[data-inviewport='slide-top'].is-inViewport{
  transform: translateY(0%);
  opacity: 1;
}

[data-inviewport='slide-left']{
  transform: translateX(-100%);
  opacity: 0;
  transition: 1s;
}

[data-inviewport='slide-left'].is-inViewport{
  transform: translateX(0%);
  opacity: 1;
}

[data-inviewport='slide-right']{
  transform: translateX(100%);
  opacity: 0;
  transition: 1s;
}

[data-inviewport='slide-right'].is-inViewport{
  transform: translateX(0%);
  opacity: 1;
}

.un {
  transition: 300ms;
  border-bottom: 2px solid transparent;
}

.un:link, .un:visited {
  color:#23c6ec
}

.un:hover {
  border-color:#23c6ec ;
}

.delay:nth-child(1n){animation-delay: 0.15s;transition-delay: 0.15s;}
.delay:nth-child(2n){animation-delay: 0.3s;transition-delay: 0.3s;}
.delay:nth-child(3n){animation-delay: 0.45s;transition-delay: 0.45s;}
.delay:nth-child(4n){animation-delay: 0.6s;transition-delay: 0.6s;}
.delay:nth-child(5n){animation-delay: 0.75s;transition-delay: 0.75s;}
.delay:nth-child(6n){animation-delay: 0.9s;transition-delay: 0.9s;}

.upload-table-header {
  width: 326px;
  max-width: 326px;
}
.upload-div {
  border: 2px dashed rgb(215, 215, 215);
}

.upload-div:hover {
  /* 悬停状态的颜色 */
  border: 2px dashed #12acd7;
  /* border-color: #12acd7; */
  color: #12acd7;
  background-color: rgb(18, 188, 215, 0.4);
}

.upload-table-uploaded-files {
  width: 326px;
  max-width: 326px;
}
.upload-table-size {
  width: 100px;
  max-width: 100px;
}
.upload-table-status {
  width: 426px;
  max-width: 426px;
}
.fc-col-header-cell-cushion {
  text-decoration: none;
  color: #000000;
}
.fc-daygrid-day-number {
  text-decoration: none;
  color: #000000;
}
.fc-h-event {
  border: none !important;
  cursor: pointer;
}
.fc .fc-button-primary {
  border: none !important;
  background-color: #12acd7;
  color:#000000;
  border-radius: 8px;
}
.fc .fc-button-primary:hover {
  border: none !important;
  background-color: #12acd7;
  color: #000000;
  border-radius: 8px;
}
.fc .fc-button-primary:disabled {
  background-color: #12acd7;
  border: none !important;
  color: #000000;
}
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: #12acd7;
  border: none;
  color: #000000;
}
.fc-dayGridMonth-button {
  visibility: hidden;
}

.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  padding: 1px;
  display: flex;
  justify-content: center;
}

.makeStyles-root {
  align-items: center;
}

